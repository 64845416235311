<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="form-wrapper">
      <image-selector
        :label="$t('COMMON.PICTURE')"
        :defaultImage="product.picture"
        ressource_name="products"
        :ressource_id="product.id ? product.id : 0"
        field="picture"
        @imageChanged="
          (file_url) => {
            product.picture = file_url;
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.attachment" />
    </div>

    <div class="form-wrapper">
      <gallery-selector
        :label="$t('COMMON.GALLERY')"
        :defaultGallery="product.gallery"
        ressource_name="products"
        :ressource_id="product.id ? product.id : 0"
        field="gallery"
        @galleryChanged="
          (gallery_urls) => {
            product.gallery = gallery_urls;
            onFormChanged();
          }
        "
      />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.ORGANIZATION')} (*)`"
        :placeholder="$t('COMMON.ORGANIZATION')"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <organization-selector
          :organization="product.organization.id"
          :filterable="true"
          :showAll="false"
          @organizationChanged="
            (organizationId) => {
              product.organization.id = organizationId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.organization" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.LOCATION')}`"
        :placeholder="$t('COMMON.LOCATION')"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
      >
        <locations-selector
          :locations="product.allowedLocations"
          :filterable="true"
          :showAll="false"
          :multiple="true"
          :organization="product.organization.id"
          @locationsChanged="
            (locations) => {
              product.allowedLocations = locations;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.location" />
      <validation-error :errors="apiValidationErrors.attachment" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.NAME')} (*)`"
        :placeholder="$t('COMMON.NAME')"
        v-model="product.name"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.name" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="$t('COMMON.EXCERPT')"
        :placeholder="$t('COMMON.EXCERPT')"
      >
        <html-editor v-model="product.excerpt" @change="onFormChanged()">
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.PRICE')} (*)`"
        :placeholder="$t('COMMON.PRICE')"
        v-model="product.price"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.price" />
    </div>

    <div class="form-wrapper full">
      <taxes-selector
        :label="$t('COMMON.TAXES')"
        :taxes="product.taxes"
        @taxesChanged="
          (taxes) => {
            product.taxes = taxes;
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.taxes" />
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{
          product.id ? $t("PRODUCTS.EDIT_PRODUCT") : $t("PRODUCTS.ADD_PRODUCT")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import models from "@/constants/models";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import ImageSelector from "@/components/ImageSelector.vue";
import GallerySelector from "@/components/GallerySelector.vue";
import TaxesSelector from "@/components/TaxesSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    OrganizationSelector,
    ImageSelector,
    GallerySelector,
    TaxesSelector,
    LocationsSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["productData", "formErrors", "loading"],

  data() {
    let productData = cloneDeep(this.productData);
    productData = this.$fillUserOrganizationData(productData);
    return {
      product: productData,
      permissions: [],
      permissionsModel: {},
      models: models,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let productData = cloneDeep(this.product);
      productData = this.$fillUserOrganizationData(productData);
      this.$emit("productSubmitted", productData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    productData(productData) {
      if (productData) {
        this.product = {
          ...this.product,
          ...cloneDeep(productData),
        };
      }
    },
  },
};
</script>
