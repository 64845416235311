var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"form-wrapper"},[_c('image-selector',{attrs:{"label":_vm.$t('COMMON.PICTURE'),"defaultImage":_vm.product.picture,"ressource_name":"products","ressource_id":_vm.product.id ? _vm.product.id : 0,"field":"picture"},on:{"imageChanged":(file_url) => {
          _vm.product.picture = file_url;
          _vm.onFormChanged();
        }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.attachment}})],1),_c('div',{staticClass:"form-wrapper"},[_c('gallery-selector',{attrs:{"label":_vm.$t('COMMON.GALLERY'),"defaultGallery":_vm.product.gallery,"ressource_name":"products","ressource_id":_vm.product.id ? _vm.product.id : 0,"field":"gallery"},on:{"galleryChanged":(gallery_urls) => {
          _vm.product.gallery = gallery_urls;
          _vm.onFormChanged();
        }}})],1),_c('div',{staticClass:"form-wrapper full"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.product.organization.id,"filterable":true,"showAll":false},on:{"organizationChanged":(organizationId) => {
            _vm.product.organization.id = organizationId;
            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1),_c('div',{staticClass:"form-wrapper"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.product.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.product.organization.id},on:{"locationsChanged":(locations) => {
            _vm.product.allowedLocations = locations;
            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.attachment}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NAME')} (*)`,"placeholder":_vm.$t('COMMON.NAME')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.product.name),callback:function ($$v) {_vm.$set(_vm.product, "name", $$v)},expression:"product.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.product.excerpt),callback:function ($$v) {_vm.$set(_vm.product, "excerpt", $$v)},expression:"product.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.PRICE')} (*)`,"placeholder":_vm.$t('COMMON.PRICE')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.product.price),callback:function ($$v) {_vm.$set(_vm.product, "price", $$v)},expression:"product.price"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.price}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('taxes-selector',{attrs:{"label":_vm.$t('COMMON.TAXES'),"taxes":_vm.product.taxes},on:{"taxesChanged":(taxes) => {
          _vm.product.taxes = taxes;
          _vm.onFormChanged();
        }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.taxes}})],1),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.product.id ? _vm.$t("PRODUCTS.EDIT_PRODUCT") : _vm.$t("PRODUCTS.ADD_PRODUCT"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }