<template>
  <div class="page-wrapper-table">
    <div class="page-wrapper-table-header">
      <supplier-selector
        v-if="
          !filterSupplier &&
          $currentUserCan($permissions.PERM_VIEW_ANY_SUPPLIERS)
        "
        :filterOrganization="filterOrganization"
        @supplierChanged="(supplierId) => (selectedSupplier = supplierId)"
      />

      <product-selector
        v-if="
          !filterProduct && $currentUserCan($permissions.PERM_VIEW_ANY_PRODUCTS)
        "
        :filterOrganization="filterOrganization"
        @productChanged="(productId) => (selectedProduct = productId)"
      />

      <div>
        <base-input
          v-model="query"
          type="search"
          prepend-icon="fas fa-search"
          :placeholder="$t('COMMON.SEARCH')"
          clearable
        />
      </div>
    </div>
    <div class="page-wrapper-table-body">
      <div class="page-wrapper-table-body-inner">
        <el-table
          class="table-responsive align-items-center table-flush supplier-product-list"
          header-row-class-name="thead-light"
          :data="filteredSupplierProducts"
          type="expand"
        >
          <div slot="empty" class="table-loading" v-if="loading">
            <img src="/img/loading.gif" style="height: 100px; width: 100px" />
          </div>
          <el-table-column type="expand">
            <template slot-scope="props">
              <form class="" @submit.prevent="">
                <taxes-selector
                  :label="$t('COMMON.TAXES')"
                  :taxes="supplierProductsModel[props.row.id].taxes"
                  @taxesChanged="
                    (taxes) =>
                      (supplierProductsModel[props.row.id].taxes = taxes)
                  "
                />

                <base-input
                  :label="$t('COMMON.EXCERPT')"
                  :placeholder="$t('COMMON.EXCERPT')"
                  input-classes="form-control-alternative"
                >
                  <html-editor
                    v-model="supplierProductsModel[props.row.id].excerpt"
                  >
                  </html-editor>
                </base-input>
              </form>
            </template>
          </el-table-column>
          <el-table-column
            v-if="!filterProduct"
            :label="$t('COMMON.PRODUCT')"
            prop="product.name"
            min-width="120px"
          >
            <template v-slot="{ row }">
              <base-input v-if="row.id < 0">
                <product-selector
                  :filterOrganization="filterOrganization"
                  :product="row.product.id"
                  :allowNone="false"
                  :showAll="false"
                  :disabled="row.id > 0"
                  @productChanged="
                    (productId, product) => {
                      supplierProductsModel[row.id].product.id = productId;
                      supplierProductsModel[row.id].sku = product.sku;
                      supplierProductsModel[row.id].price = product.price;
                      supplierProductsModel[row.id].taxes = product.taxes;
                    }
                  "
                />
              </base-input>
              <object-link v-if="row.id > 0" :object="row.product" />
            </template>
          </el-table-column>
          <el-table-column
            v-if="!filterSupplier"
            :label="$t('COMMON.SUPPLIER')"
            prop="supplier.name"
            min-width="120px"
          >
            <template v-slot="{ row }">
              <base-input v-if="row.id < 0">
                <supplier-selector
                  :filterOrganization="filterOrganization"
                  :supplier="row.supplier.id"
                  :allowNone="false"
                  :showAll="false"
                  :disabled="row.id > 0"
                  @supplierChanged="
                    (supplierId) => {
                      supplierProductsModel[row.id].supplier.id = supplierId;
                    }
                  "
                />
              </base-input>
              <object-link v-if="row.id > 0" :object="row.supplier" />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('PRODUCTS.SKU')"
            prop="sku"
            min-width="80px"
          >
            <template v-slot="{ row }">
              <base-input
                :placeholder="$t('PRODUCTS.SKU')"
                v-model="supplierProductsModel[row.id].sku"
                input-classes="form-control-alternative"
              >
              </base-input>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('COMMON.PRICE')"
            prop="price"
            min-width="40px"
          >
            <template v-slot="{ row }">
              <base-input
                class="my-5"
                :placeholder="$t('COMMON.PRICE')"
                v-model="supplierProductsModel[row.id].price"
                input-classes="form-control-alternative"
              />
            </template>
          </el-table-column>
          <el-table-column min-width="20px">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip
                content="Delete"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_EDIT_PRODUCTS)"
              >
                <a
                  type="text"
                  @click="deleteSupplierProduct(row)"
                  class="table-action table-action-delete"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fas fa-trash"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="row mt-3 add-product">
      <base-button
        class="btn-sm add-product-button"
        @click="
          () => {
            addLine();
          }
        "
      >
        <i class="fas fa-plus"></i>
        {{ addLineText }}
      </base-button>
      <base-button
        class="btn-sm add-product-save"
        @click="saveSupplierProducts()"
      >
        <i class="fas fa-save"></i>
        {{ $t("COMMON.SAVE") }}
      </base-button>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import TaxesSelector from "@/components/TaxesSelector.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import SupplierSelector from "../SupplierSelector.vue";
import ProductSelector from "../ProductSelector.vue";

export default {
  name: "product-list-table",

  components: {
    SupplierSelector,
    ProductSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    TaxesSelector,
    HtmlEditor,
  },

  mixins: [requestErrorMixin],

  props: {
    addLineText: {
      type: String,
      description: "Text for add line",
    },
    product: {
      type: Object,
      default: null,
      description: "Product",
    },
    supplier: {
      type: Object,
      default: null,
      description: "Supplier",
    },
    filterSupplier: {
      type: String,
      default: null,
      description: "Supplier id",
    },
    filterProduct: {
      type: String,
      default: null,
      description: "Product id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      filteredSupplierProducts: [],
      supplierProducts: [],
      loading: true,
      selectedProduct: null,
      selectedSupplier: null,
      supplierProductsModel: {},
      deletedSupplierProducts: {},
    };
  },

  computed: {},

  watch: {
    filterSupplier: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterProduct: {
      handler: "getListDebounced",
      immediate: true,
    },
    query: {
      handler: "getListFilteredDebounced",
      immediate: true,
    },
    selectedSupplier: {
      handler: "getListFilteredDebounced",
      immediate: true,
    },
    selectedProduct: {
      handler: "getListFilteredDebounced",
      immediate: true,
    },
    supplierProducts: {
      handler: "refreshSupplierProducts",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
      this.filterSupplierProducts();
    }, 300),

    getListFilteredDebounced: _.debounce(function () {
      this.filterSupplierProducts();
    }, 300),

    async filterSupplierProducts() {
      this.filteredSupplierProducts = this.supplierProducts.filter((item) => {
        if (item.id < 0) {
          return true;
        }
        if (this.selectedSupplier) {
          if (this.selectedSupplier != item.supplier.id) {
            return false;
          }
        }
        if (this.selectedProduct) {
          if (this.selectedProduct != item.product.id) {
            return false;
          }
        }
        if (this.query) {
          if (
            !item.supplier.company_name.includes(this.query) &&
            !item.product.name.includes(this.query) &&
            !item.sku?.includes(this.query) &&
            !item.product.sku?.includes(this.query)
          ) {
            return false;
          }
        }
        return true;
      });
    },

    async getList() {
      this.loading = true;
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: 1,
            size: 999999999,
          },
          include: "supplier,product",
        };

        if (this.filterSupplier) {
          params = {
            ...params,
            filter: { ...params.filter, supplier: this.filterSupplier },
          };
        }
        if (this.filterProduct) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              product: this.filterProduct,
            },
          };
        }

        await this.$store.dispatch("supplierProducts/list", params);
        this.supplierProducts = this.$store.getters["supplierProducts/list"];
        this.filterSupplierProducts();

        this.total = this.$store.getters["supplierProducts/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deleteSupplierProduct(row) {
      if (row.id > 0) {
        const confirmation = await swal.fire({
          title: this.$t("PRODUCTS.DELETE_THIS_SUPPLIER_PRODUCT"),
          type: "question",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: this.$t("COMMON.YES"),
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        if (confirmation.value !== true) {
          return;
        }
      }
      this.deletedSupplierProducts[row.id] = _.cloneDeep(row);
      this.supplierProducts = this.supplierProducts.filter(
        (item) => item.id != row.id
      );
      this.filterSupplierProducts();
    },

    async saveSupplierProducts() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        for (const line of Object.values(this.supplierProductsModel)) {
          const supplierProductData = _.cloneDeep(line);
          if (line.id > 0) {
            await this.$store.dispatch(
              "supplierProducts/update",
              supplierProductData
            );
          } else {
            if (
              supplierProductData.product.id &&
              supplierProductData.supplier.id &&
              supplierProductData.price
            ) {
              delete supplierProductData.id;
              await this.$store.dispatch(
                "supplierProducts/add",
                supplierProductData
              );
            }
          }
        }
        for (const line of Object.values(this.deletedSupplierProducts)) {
          if (line.id > 0) {
            await this.$store.dispatch("supplierProducts/destroy", line.id);
          }
        }
        await this.getList();
        this.deletedSupplierProducts = {};
        this.$notify({
          type: "success",
          message: this.$t("PRODUCTS.PRODUCTS_SAVED"),
        });
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
      }
    },

    addLine() {
      let newLinesIds = Object.values(this.supplierProducts)
        .filter((item) => item.id < 0)
        .map((item) => item.id);
      newLinesIds.push(-1);
      const newLineId = Math.min(...newLinesIds) - 1;

      const newSupplierProduct = {
        type: "supplier-products",
        id: newLineId,
        sku: this.product ? this.product.sku : null,
        price: this.product ? this.product.price : 0,
        taxes: this.product?.taxes || this.supplier?.taxes,
        relationshipNames: ["supplier", "product"],
        supplier: {
          type: "suppliers",
          id: this.supplier ? this.supplier.id : null,
        },
        product: {
          type: "products",
          id: this.product ? this.product.id : null,
        },
      };
      this.supplierProducts.push(_.cloneDeep(newSupplierProduct));
      this.supplierProductsModel[newLineId] = _.cloneDeep(newSupplierProduct);
      this.filterSupplierProducts();
    },

    refreshSupplierProducts() {
      const supplierProductsModelData = {};
      for (const item of this.supplierProducts) {
        if (this.supplierProductsModel[item.id]) {
          supplierProductsModelData[item.id] =
            this.supplierProductsModel[item.id];
        } else {
          supplierProductsModelData[item.id] = _.cloneDeep(item);
        }
      }
      this.supplierProductsModel = supplierProductsModelData;
    },
  },
};
</script>

<style>
.supplier-product-list .el-table__row .form-group {
  margin-bottom: 0;
}
.supplier-product-list .form-group.taxes-selector .remove-taxes-button {
  display: flex;
  align-items: center;
}
</style>
